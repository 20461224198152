@font-face {
  font-family: 'Aspira';
  font-weight: 400;
  font-style: normal;
  src: url('./Aspira Demi.otf') format('opentype');
}

@font-face {
  font-family: 'Aspira';
  font-weight: 300;
  font-style: normal;
  src: url('./Aspira Light.otf') format('opentype');
}

@font-face {
  font-family: 'Aspira';
  font-weight: 500;
  font-style: normal;
  src: url('./Aspira Black.otf') format('opentype');
}
